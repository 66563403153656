<template lang="pug">
.vh-100
</template>

<script>
import axios from 'axios';
import sweetalert from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  async created() {
    const token = this.$route.query['token'];

    const response = await axios.post(
      `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/register/check-token`,
      {
        token,
      }
    );

    const status = response.data.data.status;

    if (!status) {
      this.$router.push({
        path: '/404',
      });

      return;
    }
    await sweetalert.fire({
      title: '<strong style="color: #006BDA;">信箱驗證成功</strong><br><br><img src="https://cdn2.wuwow.tw/wuwow-junior/correct.png"><br>',
      html: '<br>歡迎您加入WUWOW Jr大家庭',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 3000,
    });
    this.$router.push('/');
  },
};
</script>
<style lang="scss">
  .swal2-container {
    background-image: url(https://cdn2.wuwow.tw/wuwow-junior/vip-background-web.svg);
    background-size: cover;
    .swal2-modal {
      // background: rgba(255, 255, 255, 0.75) !important;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 0px 14px rgba(208, 208, 208, 0.25);
      border-radius: 15px;
      padding: 50px 20px !important;
      .swal2-title {
        margin-bottom: 30px;
      }
    }
    .swal2-success-circular-line-right {
      background: #ffffff00;
    }
  }
</style>
